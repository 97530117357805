import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import ContactsSearchForm from "../components/contacts-search-form"
import Papa from "papaparse"
import { Link } from "gatsby"

class Contacts extends Component {

  constructor(props) {
    super(props);
    this.host = this.props.location.origin;
    this.state = {
      contacts: [],
      filteredContacts: []
    }

    this.filterContacts = this.filterContacts.bind(this);
  }

  filterContacts(filteredContacts) {
    this.setState({filteredContacts: filteredContacts});
  }

  componentDidMount() {
    let self = this;

    // Use PapaParse to request CSV and parse to JSON
    Papa.parse(this.host + '/contacts.csv?v=20210625', {
      download: true,
      header: true,
      complete: function(results) {
        let contacts = results.data.filter(function (c) {
          // Prevent empty rows from causing issues
          return c.fname !== "";
        });

        contacts.sort(function(a,b) {
          return (a.lname.toLowerCase() > b.lname.toLowerCase()) ? 1 : ((b.lname.toLowerCase() > a.lname.toLowerCase()) ? -1 : 0);
        });

        self.setState({contacts: contacts});
      }
    });
  }

  render() {
    let contactsList = "";
    if (this.state.filteredContacts.length > 0) {
      contactsList =
      (<div className="table-container">
        <table className="responsive" tabIndex="0">
        <thead>
          <tr>
            <th className="col-contact">Contact</th>
            <th className="col-phone">Phone Number(s)</th>
            <th className="col-business">Company/Agency</th>
            <th className="col-business-type">Business Type</th>
            <th className="col-city">City, State/Province</th>
            <th className="col-county">County</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.filteredContacts.map((contact, index) => {
              return (
                <tr key={index}>
                  <td className="word-wrap">{contact.fname + ' ' + contact.lname}<br /><a href={'mailto:' + contact.email}>{contact.email}</a></td>
                  <td className="word-wrap">{contact.phone1}<br />{contact.phone2}</td>
                  <td>
                    {contact.businessName}<br />
                    {contact.address1 + ' ' + contact.address2}<br />
                    {contact.city + ', ' + contact.state + ' ' + contact.zip}
                  </td>
                  <td className="word-wrap">{contact.businessType}</td>
                  <td>{contact.city + ', ' + contact.state}</td>
                  <td className="word-wrap">{contact.county}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
      </div>);
    }

    return (
      <Layout pageTitle="Contacts" pathName={this.props.location.pathname} hasSidebar={false}>
        <SEO title="Contacts" />
        <Content>
          <p>Search our list of member contacts below. To join the task force and be added to this list, <Link to="/become-a-member">fill out this form.</Link> Please contact <a href="mailto:NCTTF@nicb.org">NCTTF@nicb.org</a> if any of your information has changed or you want to be removed from the contact list.</p>
          <ContactsSearchForm contacts={this.state.contacts} filterContacts={this.filterContacts}/>
          <h3>Displaying <em><strong>{this.state.filteredContacts.length}</strong></em> results.</h3>
          {contactsList}
        </Content>
      </Layout>
    );
  }
}

export default Contacts
