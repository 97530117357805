import React, { Component } from "react"
import FormGroup from "./form-group";

class ContactsSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counties: [],
      states: [],
      businessTypes: [],
      selectOptionsSet: false,
      filters: {}
    }

    this.loadingSpinner = React.createRef();
    // Bind submit handler
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.state.selectOptionsSet === false && typeof this.props.contacts !== 'undefined' && this.props.contacts.length > 0) {
      this.setSelectOptions();
    }
  }

  setSelectOptions() {
    let states = [];
    let counties = [];
    let businessTypes = [];

    // Loop contact data to set up individual arrays for select inputs
    this.props.contacts.forEach((contact) => {
      if (contact.state && states.indexOf(contact.state) === -1) {
        states.push(contact.state);
      }

      if (contact.county && counties.indexOf(contact.county) === -1) {
        counties.push(contact.county);
      }

      if (contact.businessType && businessTypes.indexOf(contact.businessType) === -1) {
        businessTypes.push(contact.businessType);
      }
    });

    this.setState(state => ({
      counties: counties.sort(),
      states: states.sort(),
      businessTypes: businessTypes.sort(),
      selectOptionsSet: true
    }));
  }

  handleSubmit(event) {
    event.preventDefault();
    let loadingSpinner = this.loadingSpinner;
    let filtered = this.multiFilter(this.props.contacts, this.state.filters);
    loadingSpinner.current.style.display = 'block';
    this.props.filterContacts(filtered);
    setTimeout(function() { loadingSpinner.current.style.display = 'none'; }, 500);
  }

  handleInputChange(event) {
    let filters = this.state.filters;
    filters[event.target.name] = event.target.value;
    this.setState({filters: filters});
  }

  multiFilter(contacts, filters) {
    const filterKeys = Object.keys(filters);
    // filters all elements passing the criteria
    return contacts.filter((item) => {
      // dynamically validate all filter criteria
      return filterKeys.every(key => {
        // ignores an empty filter
        if (!filters[key].length) return true;

        // If one of the select input filters
        if (['state', 'county', 'businessType'].indexOf(key) > -1) {
          // Exact match
          return filters[key] === item[key];
        }
        else {
          return (item[key].toLowerCase()).includes(filters[key].toLowerCase());
        }
      });
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>

        <div className="row">
          <FormGroup labelText="State/Province:" labelFor="state">
            <select className="form-control" id="state" name="state" onChange={this.handleInputChange}>
              <option value="">-- All States --</option>
              {
                this.state.states.map((state, index) => {
                  return (
                    <option key={index} value={state}>{state}</option>
                  );
                }
                )}
            </select>
          </FormGroup>
          <FormGroup labelText="County:" labelFor="county">
            <select className="form-control" id="county" name="county" onChange={this.handleInputChange}>
              <option value="">-- All Counties --</option>
              {
                this.state.counties.map((county, index) => {
                  return (
                    <option key={index} value={county}>{county}</option>
                  );
                }
                )}
            </select>
          </FormGroup>
          <FormGroup labelText="City:" labelFor="city">
            <input className="form-control" id="city" name="city" type="text" onChange={this.handleInputChange} />
          </FormGroup>
          <FormGroup labelText="Postal Code:" labelFor="zip">
            <input className="form-control" id="zip" name="zip" type="text" onChange={this.handleInputChange} />
          </FormGroup>
          <FormGroup labelText="First Name:" labelFor="fname">
            <input className="form-control" id="fname" name="fname" type="text" onChange={this.handleInputChange} />
          </FormGroup>
          <FormGroup labelText="Last Name:" labelFor="lname">
            <input className="form-control" id="lname" name="lname" type="text" onChange={this.handleInputChange} />
          </FormGroup>
          <FormGroup labelText="Company/Agency:" labelFor="businessName">
            <input className="form-control" id="businessName" name="businessName" type="text" onChange={this.handleInputChange} />
          </FormGroup>

          <FormGroup labelText="Business Type:" labelFor="businessType">
            <select className="form-control" id="businessType" name="businessType" onChange={this.handleInputChange}>
              <option value="">-- All Business Types --</option>
              {
                this.state.businessTypes.map((businessType, index) => {
                  return (
                    <option key={index} value={businessType}>{businessType}</option>
                  );
                }
                )}
            </select>
          </FormGroup>
        </div>

        <div className="submit-container">
          <button type="submit" className="nicb-button">Search</button>
          <div id="loading-spinner" ref={this.loadingSpinner}></div>
        </div>
      </form>
    );
  }
}

export default ContactsSearchForm
