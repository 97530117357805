import React from "react"

const FormGroup = ({children, labelText, labelFor, isRequired}) => (
  <div className="form-group col-md-6">
    <label htmlFor={labelFor} className={`${isRequired ? "form-required" : ""}`}>{labelText}</label>
    {children}
  </div>
);

export default FormGroup;
